import axios from "axios";

// Cria uma instância do Axios
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // URL base configurável via variáveis de ambiente
  headers: {
    "Content-Type": "application/json",
  },
});

// Adiciona um interceptor para incluir o token JWT automaticamente em todas as requisições
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Busca o token JWT no localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Adiciona o token no cabeçalho Authorization
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
