import React, { createContext, ReactNode, useContext, useState } from "react";
import useHoliday, {
  HolidayType,
  CreateHolidayType,
  UpdateHolidayType,
  HolidayFilters,
} from "./hooks/useHoliday";
import { useToast } from "./ToastContext";
import { AuthContext } from "./AuthContext";

interface HolidayContextProviderProps {
  children: ReactNode;
}

export interface HolidayContextData {
  getAllHolidaysPaged: (
    pageNumber: number,
    filters?: HolidayFilters
  ) => Promise<HolidayType[] | null>;
  createHoliday: (data: CreateHolidayType) => Promise<HolidayType | null>;
  updateHoliday: (data: UpdateHolidayType) => Promise<HolidayType | null>;
  deleteHoliday: (id: number) => Promise<void>;
  listHolidays: HolidayType[];
  setListHolidays: (holidays: HolidayType[]) => void;
  pageCount: number;
  setPageCount: (count: number) => void;
  holiday: HolidayType | null;
  setHoliday: (holiday: HolidayType | null) => void;
}

const initialState: HolidayContextData = {
  getAllHolidaysPaged: async () => null,
  createHoliday: async () => null,
  updateHoliday: async () => null,
  deleteHoliday: async () => {},
  listHolidays: [],
  setListHolidays: () => {},
  pageCount: 0,
  setPageCount: () => {},
  holiday: null,
  setHoliday: () => {},
};

export const HolidayContext = createContext<HolidayContextData>(initialState);

export default function HolidayContextProvider({
  children,
}: HolidayContextProviderProps) {
  const {
    getAllHolidaysPaged,
    createHoliday,
    updateHoliday,
    deleteHoliday,
    listHolidays,
    setListHolidays,
    pageCount,
    setPageCount,
  } = useHoliday();

  const [holiday, setHoliday] = useState<HolidayType | null>(null);

  return (
    <HolidayContext.Provider
      value={{
        getAllHolidaysPaged,
        createHoliday,
        updateHoliday,
        deleteHoliday,
        listHolidays,
        setListHolidays,
        pageCount,
        setPageCount,
        holiday,
        setHoliday,
      }}
    >
      {children}
    </HolidayContext.Provider>
  );
}

export const useHolidayContext = () => {
  const context = useContext(HolidayContext);
  if (!context) {
    throw new Error("useHolidayContext must be used within a HolidayContextProvider");
  }
  return context;
};
