import { useState, useContext } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { AuthContext } from "../AuthContext";
import { LoadingContext } from "../LoadingContext";

export interface HolidayType {
    id: number;
    date: string;
    name: string;
    category: number;
}

export interface CreateHolidayType {
    officeId: number;
    date: string;
    name: string;
    category: number;
}

export interface UpdateHolidayType {
    id: number;
    date: string;
    name: string;
    category: number;
}

export interface HolidayFilters {
    officeId: number;
    name?: string;
    category?: number;
    startDate?: string;
    endDate?: string;
}

export default function useHoliday() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const { handleLogout } = useContext(AuthContext);
    const [listHolidays, setListHolidays] = useState<HolidayType[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);

    async function getAllHolidaysPaged(
        pageNumber: number,
        filters?: HolidayFilters
    ): Promise<HolidayType[] | null> {
        setLoading(true);
        try {
            const params = {
                pageNumber,
                pageSize: 10,
                ...filters,
            };
            const response = await api.get(`/Holiday/getAllPaged`, { params });
            if (response.status === 200) {
                setListHolidays(response.data.result);
                setPageCount(response.data.pageCount);
                return response.data.result;
            } else {
                showToast("error", "Erro ao carregar feriados.");
                return null;
            }
        } catch (error: any) {
            if (error.response?.status === 401) {
                handleLogout();
                showToast("error", "Sessão expirada. Por favor, faça login novamente.");
            } else {
                showToast("error", "Erro ao carregar feriados.", error);
            }
            return null;
        } finally{
            setLoading(false);
        }
    }

    async function createHoliday(data: CreateHolidayType): Promise<HolidayType | null> {
        setLoading(true);
        try {
            const response = await api.post(`/Holiday/create`, data);
            if (response.status === 200) {
                showToast("success", "Feriado criado com sucesso.");
                return response.data;
            } else {
                showToast("error", "Erro ao criar feriado.");
                return null;
            }
        } catch (error: any) {
            if (error.response?.status === 401) {
                handleLogout();
                showToast("error", "Sessão expirada. Por favor, faça login novamente.");
            } else {
                showToast("error", "Erro ao criar feriado.", error.response?.data);
            }
            return null;
        } finally {
            setLoading(false);
        }
    }

    async function updateHoliday(data: UpdateHolidayType): Promise<HolidayType | null> {
        setLoading(true);
        try {
            const response = await api.put(`/Holiday/${data.id}`, data);
            if (response.status === 200) {
                showToast("success", "Feriado atualizado com sucesso.");
                return response.data;
            } else {
                showToast("error", "Erro ao atualizar feriado.");
                return null;
            }
        } catch (error: any) {
            if (error.response?.status === 401) {
                handleLogout();
                showToast("error", "Sessão expirada. Por favor, faça login novamente.");
            } else {
                showToast("error", "Erro ao atualizar feriado.", error);
            }
            return null;
        } finally {
            setLoading(false);
        }
    }

    async function deleteHoliday(id: number): Promise<void> {
        setLoading(true);
        try {
            const response = await api.delete(`/Holiday/${id}`);
            if (response.status === 200) {
                showToast("success", "Feriado deletado com sucesso.");
            } else {
                showToast("error", "Erro ao deletar feriado.");
            }
        } catch (error: any) {
            if (error.response?.status === 401) {
                handleLogout();
                showToast("error", "Sessão expirada. Por favor, faça login novamente.");
            } else {
                showToast("error", "Erro ao deletar feriado.", error);
            }
        } finally {
            setLoading(false);
        }
    }

    return {
        getAllHolidaysPaged,
        createHoliday,
        updateHoliday,
        deleteHoliday,
        listHolidays,
        setListHolidays,
        pageCount,
        setPageCount,
    };
}
