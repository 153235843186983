import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface EmailType {
    from: string;
    to: string[];
    subject: string;
    text: string;
    html: string;
    templateId: string;
}

export default function useEmail() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const { handleLogout, objUserTk } = useContext(AuthContext);
    const [Email, setEmail] = useState<EmailType>({
        from: "",
        to: [],
        subject: "",
        text: "",
        html: "",
        templateId: "",
    });
    
    async function sendNotificationEmail(emailData: EmailType): Promise<boolean> {
        setLoading(true);
        try {
            const payload = {
                from: { email: emailData.from },
                to: emailData.to.map((email) => ({ email })),
                Subject: emailData.subject,
                Text: emailData.text,
                Html: emailData.html,
                TemplateId: emailData.templateId,
            };
            console.log(payload);
            const response = await api.post("/Email/send-notification-email", payload);
            showToast("success", "E-mail enviado com sucesso!");
            setLoading(false);
            return true;
        } catch (error: any) {
            if (error?.response?.status === 401) {
                handleLogout();
                showToast(
                    "error",
                    "Sessão desconectada. Favor login novamente.",
                    error
                );
            } else {
                showToast(
                    "error",
                    "Erro ao enviar e-mail. Verifique novamente mais tarde.",
                    error
                );
            }
            setLoading(false);
            return false;
        }
    }

    return {
        sendNotificationEmail,
        Email,
        setEmail,
    };
}