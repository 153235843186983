import React, { useContext, useEffect, useState } from 'react';
import {
    Container,
    Table,
    Button,
    Card,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CardBody,
    Form,
    FormGroup,
    Label
} from 'reactstrap';
import PaginationMaterial from "../../components/PaginationMaterial/PaginationMaterial";
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Header from '../../components/Headers/Header';
import Swal from "sweetalert2";
import AdminNavbar from '../../components/Navbars/AdminNavbar/AdminNavbar';
import './Routine.css';
import { AuthContext } from '../../context/AuthContext';
import { RoutineContext } from '../../context/RoutineContext';
import { RoutineType, CreateRoutineType, UpdateRoutineType } from '../../context/hooks/useRoutine';
import { CustomerContext } from '../../context/CustomerContext';
import { TaxTasksContext } from '../../context/TaxTasksContext';
import { TaxTasksType, UpdateTaxTasksType } from '../../context/hooks/useTaxTasks';
import { DownloadContext } from '../../context/DownloadContext';
import { FilesContext } from '../../context/FilesContext';
import { EmailContext } from '../../context/EmailContext';
import { RoutineTypeContext } from '../../context/RoutineTypeContext';
import { format, parseISO } from 'date-fns';
import useUser from '../../context/hooks/useUser';

const Routine = () => {
    const { objUserTk } = useContext(AuthContext);
    const {
        getAllRoutinePaged,
        listRoutine,
        pageCount,
        setRoutine,
        routine,
        createRoutine,
        importRoutine,
        updateRoutine,
        deleteRoutine,
    } = useContext(RoutineContext);
    const {
        getAllCustomersPaged,
        getAllCustomerNames,
        listCustomer,
        listCustomerNames,
    } = useContext(CustomerContext);
    const {
        getAllTaxTaskPaged,
        updateTaxTasks,
        listTaxTasks,
        getTaxTaskById,
    } = useContext(TaxTasksContext);
    const { sendNotificationEmail } = useContext(EmailContext);
    const { handleDownloadAll, handleDownloadByFilename, handleDownloadByTaxTasksId } = useContext(DownloadContext);
    const { getFileName } = useContext(FilesContext);
    const {
        getAllRoutineTypePaged,
        listRoutineType,
    } = useContext(RoutineTypeContext);
    const { getPermissionsByUser, userPermissions } = useUser();
    const [selectedRoutineId, setSelectedRoutineId] = useState(0);
    const [newRoutine, setNewRoutine] = useState<CreateRoutineType>({
        description: '',
        initialDate: '',
        finalDate: null,
        numberOfCharges: null,
        frequency: 0,
        billingFrequency: 0,
        officeId: objUserTk.OfficeId,
        customerId: 0,
        routineTypeId: 0,
        emails: [],
        tags: [],
        businessDayOfMonth: 0,
        onlyBusinessDays: false,
    });

    const [editingRoutine, setEditingRoutine] = useState<UpdateRoutineType | null>(null);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editingTaxTasks, setEditingTaxTasks] = useState<UpdateTaxTasksType | null>(null);
    const [selectedTaxTasksId, setSelectedTaxTasksId] = useState(0);
    const [editTaxTasksModalOpen, setEditTaxTasksModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    const [filters, setFilters] = useState({
        customerId: '',
        description: '',
        initialDate: '',
        finalDate: '',
        frequency: '',
    });

    useEffect(() => {
        setLoading(true);

        getAllCustomerNames(objUserTk.OfficeId);
        getAllRoutineTypePaged(1, objUserTk.OfficeId);
        getAllRoutinePaged(1, objUserTk.OfficeId)
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao buscar rotinas:", error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        const fetchPermissions = async () => {
            if (objUserTk?.UserId) {
                const permissions = await getPermissionsByUser(objUserTk.UserId);
            }
        };

        fetchPermissions().catch(error => console.error("Erro ao carregar permissões:", error));
    }, [objUserTk?.UserId]);

    const onChangePaginationCommon = async (e: any, value: any) => {
        await getAllRoutinePaged(value, objUserTk.OfficeId);
    };

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleApproveTask = () => {
        if (editingTaxTasks) {
            const approvedTask = { ...editingTaxTasks, status: 5 };
            updateTaxTasks(approvedTask)
                .then(updatedTaxTasks => {
                    if (updatedTaxTasks) {
                        // setEditTaxTasksModalOpen(false);
                        handleCloseEditModal();
                        getAllTaxTaskPaged(1, 0, objUserTk.OfficeId);
                        setEditingTaxTasks(null);
                    } else {
                        console.error("Erro ao aprovar tarefa.");
                    }
                })
                .catch(error => {
                    console.error("Erro ao aprovar tarefa:", error);
                });
        }
    };

    const handleSearch = () => {
        setLoading(true);
        getAllRoutinePaged(
            1,
            objUserTk.OfficeId,
            filters.customerId ? Number(filters.customerId) : undefined,
            0,
            filters.description,
            filters.initialDate,
            filters.finalDate,
            filters.frequency
        )
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao aplicar filtros:", error);
                setLoading(false);
            });
    };

    const handleClearFilters = () => {
        getAllRoutinePaged(1, objUserTk.OfficeId)
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao limpar filtros:", error);
                setLoading(false);
            });
    };

    const handleCreateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewRoutine(prevRoutine => ({
            ...prevRoutine,
            [name]: value === '' ? null : value
        }));
    };

    const handleCreateRoutine = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const payload = {
            ...newRoutine,
            customerId: Number(newRoutine.customerId),
            numberOfCharges: newRoutine.numberOfCharges !== null ? Number(newRoutine.numberOfCharges) : null,
            frequency: Number(newRoutine.frequency),
            billingFrequency: Number(newRoutine.billingFrequency),
            officeId: Number(newRoutine.officeId)
        };

        createRoutine(payload)
            .then(createdRoutine => {
                if (createdRoutine) {
                    setCreateModalOpen(false);
                    setNewRoutine({
                        description: '',
                        initialDate: '',
                        finalDate: '',
                        numberOfCharges: null,
                        frequency: 0,
                        billingFrequency: 0,
                        officeId: Number(objUserTk.OfficeId),
                        customerId: 0,
                        routineTypeId: 0,
                        emails: [],
                        tags: [],
                        businessDayOfMonth: 0,
                        onlyBusinessDays: false,
                    });
                    getAllRoutinePaged(1, objUserTk.OfficeId);
                } else {
                    console.error("Erro ao criar rotina.");
                }
            })
            .catch(error => {
                console.error("Erro ao criar rotina:", error);
            });
    };

    const handleEditRoutine = (routine: RoutineType) => {
        const updateData: UpdateRoutineType = {
            id: routine.id,
            description: routine.description,
            routineTypeId: Number(routine.routineTypeId),
            emails: routine.emails || [],
            tags: routine.tags || [],
        };
        setEditingRoutine(updateData);
        setEditModalOpen(true);

        setLoading(true);
        try {
            getAllTaxTaskPaged(1, routine.id, objUserTk.OfficeId);
            setLoading(false);
        } catch (error) {
            console.error("Erro ao buscar tarefas da rotina:", error);
            setLoading(false);
        }
    };

    const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editingRoutine) {
            updateRoutine(editingRoutine)
                .then(updatedRoutine => {
                    if (updatedRoutine) {
                        // setEditModalOpen(false);
                        handleCloseEditModal();
                        getAllRoutinePaged(1, objUserTk.OfficeId);
                        setEditingRoutine(null);
                    } else {
                        console.error("Erro ao atualizar rotina.");
                    }
                })
                .catch(error => {
                    console.error("Erro ao atualizar rotina:", error);
                });
        }
    };

    const handleDeleteRoutine = (routineId: number) => {
        Swal.fire({
            title: "Tem certeza?",
            text: "Você não poderá desfazer esta ação!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, excluir!",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRoutine(routineId)
                    .then((deletedRoutine) => {
                        if (deletedRoutine) {
                            Swal.fire(
                                "Excluído!",
                                "A rotina foi excluída com sucesso.",
                                "success"
                            );
                            getAllRoutinePaged(1, objUserTk.OfficeId);
                        } else {
                            console.error("Erro ao excluir rotina.");
                        }
                    })
                    .catch((error) => {
                        console.error("Erro ao excluir rotina:", error);
                        Swal.fire(
                            "Erro!",
                            "Não foi possível excluir a rotina. Tente novamente mais tarde.",
                            "error"
                        );
                    })
                    .finally(() => {
                        getAllRoutinePaged(1, objUserTk.OfficeId);
                    });
            }
        });
    };

    const handleDownloadFile = async (officeId: number, taxTasksId: number) => {
        await handleDownloadByTaxTasksId(officeId, taxTasksId);
    };

    const formatDateTime = (dateString: string) => {
        if (!dateString) return 'Data não disponível';
        const date = parseISO(dateString);
        return dateString.includes('T')
            ? format(date, 'dd/MM/yyyy HH:mm')
            : format(date, 'dd/MM/yyyy');
    };

    const formatTaskStatus = (status: number) => {
        switch (status) {
            case 1:
                return { label: 'Em Aberto', color: '#007bff' };
            case 2:
                return { label: 'Concluído', color: '#28a745' };
            case 3:
                return { label: 'Atrasado', color: '#ffc107' };
            case 4:
                return { label: 'Rejeitado', color: '#dc3545' };
            case 5:
                return { label: 'Aprovado', color: '#ffcc00' }
            default:
                return { label: 'Desconhecido', color: '#6c757d' };
        }
    };

    const handleEditTaxTasks = (taxTasks: TaxTasksType) => {
        const updateData: UpdateTaxTasksType = {
            id: taxTasks.id,
            observation: taxTasks.observation,
        };
        setEditingTaxTasks(updateData);
        setEditTaxTasksModalOpen(true);
    };

    const handleEditTaxTasksSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editingTaxTasks) {
            updateTaxTasks(editingTaxTasks)
                .then(updatedTaxTasks => {
                    if (updatedTaxTasks) {
                        setEditTaxTasksModalOpen(false);
                        getAllTaxTaskPaged(1, 0, objUserTk.OfficeId);
                        setEditingTaxTasks(null);
                    } else {
                        console.error("Erro ao atualizar tarefa.");
                    }
                })
                .catch(error => {
                    console.error("Erro ao atualizar tarefa:", error);
                });
        }
    };

    const handleRejectTask = () => {
        if (editingTaxTasks) {
            const rejectedTask = { ...editingTaxTasks, status: 4 };
            updateTaxTasks(rejectedTask)
                .then(updatedTaxTasks => {
                    if (updatedTaxTasks) {
                        // setEditTaxTasksModalOpen(false);
                        handleCloseEditModal();
                        getAllTaxTaskPaged(1, 0, objUserTk.OfficeId);
                        setEditingTaxTasks(null);
                    } else {
                        console.error("Erro ao rejeitar tarefa.");
                    }
                })
                .catch(error => {
                    console.error("Erro ao rejeitar tarefa:", error);
                });
        }
    };

    const handleCloseEditModal = () => {
        setEditTaxTasksModalOpen(false);
        Swal.fire({
            title: 'Deseja enviar um e-mail para notificar o cliente?',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            icon: 'question'
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (!editingTaxTasks || !editingTaxTasks.id) {
                    Swal.fire("Erro", "Nenhuma tarefa válida foi selecionada para notificação.", "error");
                    return;
                }

                try {
                    const taskDetails = await getTaxTaskById(editingTaxTasks.id);

                    if (!taskDetails || !taskDetails.routine) {
                        Swal.fire("Erro", "Não foi possível obter os detalhes da tarefa ou rotina.", "error");
                        return;
                    }

                    const routineEmails = taskDetails.routine.emails;
                    if (!routineEmails || routineEmails.length === 0) {
                        Swal.fire("Aviso", "A rotina não possui e-mails configurados.", "warning");
                        return;
                    }

                    const nomeTarefa = taskDetails.routine.description || "Tarefa sem descrição";

                    const emailData = {
                        from: "info@b7inovacao.com.br",
                        to: routineEmails,
                        subject: "Atualização da tarefa",
                        text: `Informamos que a tarefa "${nomeTarefa}" foi atualizada.`,
                        html: `<p>Informamos que a tarefa <strong>${nomeTarefa}</strong> foi atualizada.</p>`,
                        templateId: "",
                    };

                    const success = await sendNotificationEmail(emailData);

                    if (success) {
                        Swal.fire("Sucesso", "E-mail enviado aos destinatários com sucesso.", "success");
                    } else {
                        Swal.fire("Erro", "Não foi possível enviar o e-mail aos destinatários.", "error");
                    }
                } catch (error) {
                    Swal.fire("Erro", "Ocorreu um erro ao buscar os detalhes ou enviar o e-mail.", "error");
                    console.error("Erro ao processar a notificação por e-mail:", error);
                }
            }
        });
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleImportSubmit = async () => {
        if (selectedFile) {
            await importRoutine(selectedFile, objUserTk.OfficeId);
            getAllRoutinePaged(1, objUserTk.OfficeId);
            setImportModalOpen(false);
        } else {
            alert("Por favor, selecione um arquivo.");
        }
    };

    return (
        <>
            <LoadingSpinner />
            <Header />
            <AdminNavbar
                brandText={"Gerenciar Rotinas"}
                search={false}
                titleSearch={""}
            />

            <Container className="mt-5">
                <Row>
                    <Col xs="12">
                        <Form className="d-flex search-form">
                            <FormGroup className="mr-3">
                                <Label for="customerId">Cliente</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="select"
                                    name="customerId"
                                    id="customerId"
                                    value={filters.customerId}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">Selecione um Cliente</option>
                                    {listCustomerNames.map((customer) => (
                                        <option key={customer.id} value={customer.id}>
                                            {customer.razaoSocial}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>

                            <FormGroup className="mr-3">
                                <Label for="description">Rotina</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="text"
                                    name="description"
                                    id="description"
                                    value={filters.description}
                                    placeholder='Descrição da rotina'
                                    onChange={handleFilterChange}
                                />
                            </FormGroup>

                            <FormGroup className="mr-3">
                                <Label for="initialDate">Data Inicial</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="date"
                                    name="initialDate"
                                    id="initialDate"
                                    value={filters.initialDate}
                                    onChange={handleFilterChange}
                                />
                            </FormGroup>

                            <FormGroup className="mr-3">
                                <Label for="finalDate">Data Final</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="date"
                                    name="finalDate"
                                    id="finalDate"
                                    value={filters.finalDate}
                                    onChange={handleFilterChange}
                                />
                            </FormGroup>

                            <FormGroup className="mr-3">
                                <Label for="frequency">Frequência</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="select"
                                    name="frequency"
                                    id="frequency"
                                    value={filters.frequency}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">Selecione a Frequência</option>
                                    <option value="0">Apenas uma vez</option>
                                    <option value="1">Diária</option>
                                    <option value="2">Semanal</option>
                                    <option value="3">Mensal</option>
                                    <option value="4">Trimestral</option>
                                    <option value="5">Semestral</option>
                                    <option value="6">Anual</option>
                                </Input>
                            </FormGroup>
                            <Button className="search-btn" onClick={handleSearch}>Pesquisar</Button>
                            <Button className="clear-btn" onClick={handleClearFilters}>Limpar</Button>
                        </Form>
                        {userPermissions.includes(1) && (
                            <div>
                                <Button
                                    style={{ color: "#FFF", backgroundColor: "#585858", float: "left" }}
                                    className="btn-filter"
                                    onClick={() => setCreateModalOpen(true)}
                                >
                                    Adicionar Rotina
                                </Button>
                                <Button
                                    style={{ color: "#FFF", backgroundColor: "#17a2b8", marginLeft: "10px" }}
                                    size="m"
                                    onClick={() => setImportModalOpen(true)}
                                >
                                    Importar rotinas
                                </Button>
                            </div>
                        )}
                    </Col>
                </Row>

                {userPermissions.includes(2) ? (
                    <Row className="mt-4">
                        <Col>
                            <Table hover responsive className="table-striped custom-table">
                                <thead>
                                    <tr>
                                        <th>Cliente</th>
                                        <th>Tipo da rotina</th>
                                        <th>Rotina</th>
                                        <th>Data final</th>
                                        <th>Frequência</th>
                                        <th>Número de cobranças</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listRoutine.map((routine: RoutineType, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td>{routine.razaoSocial}</td>
                                                <td>{routine.type}</td>
                                                <td>{routine.description.length > 20 ? routine.description.substring(0, 20) + '...' : routine.description}</td>
                                                <td>{formatDateTime(routine.finalDate)}</td>
                                                <td>{routine.frequency}</td>
                                                <td>{routine.numberOfCharges}</td>
                                                <td>
                                                    {userPermissions.includes(3) && (
                                                        <Button onClick={() => { handleEditRoutine(routine); setSelectedRoutineId(routine.id); }}><i className="fa-solid fa-pen-to-square"></i></Button>
                                                    )}
                                                    {userPermissions.includes(4) && (
                                                        <Button onClick={() => handleDeleteRoutine(routine.id)} color="danger"><i className="fa-solid fa-trash"></i></Button>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-end mt-3">
                                {pageCount && (
                                    <div className="d-flex justify-content-center mt-4">
                                        <PaginationMaterial
                                            onChange={(e: any, value: any) => onChangePaginationCommon(e, value)}
                                            count={pageCount}
                                        />
                                    </div>
                                )}
                                {listRoutine.length === 0 && (
                                    <div style={{ color: "red" }}>NÃO FOI ENCONTRADO NENHUM REGISTRO</div>
                                )}
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Row className="mt-4">
                        <Col>
                            <div style={{ color: "red", textAlign: "center" }}>
                                Você não tem permissão para visualizar as rotinas.
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>

            <Modal isOpen={createModalOpen} toggle={() => setCreateModalOpen(!createModalOpen)} backdrop="static" size="lg">
                <ModalHeader toggle={() => setCreateModalOpen(!createModalOpen)}>Adicionar nova rotina</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleCreateRoutine}>
                        <FormGroup>
                            <Label className="form-control-label" htmlFor="input-customer">Selecione o cliente para o qual a rotina será aplicada:</Label>
                            <Input
                                className="form-control-alternative"
                                type="select"
                                name="customerId"
                                id="customerId"
                                value={newRoutine.customerId}
                                onChange={handleCreateInputChange}
                                required
                            >
                                <option value="">Selecione um Cliente</option>
                                {listCustomerNames.map((customer) => (
                                    <option key={customer.id} value={customer.id}>
                                        {customer.razaoSocial}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label className="form-control-label" htmlFor="input-routineType">Selecione o tipo da rotina:</Label>
                            <Input
                                className="form-control-alternative"
                                type="select"
                                name="routineTypeId"
                                id="routineTypeId"
                                value={newRoutine.routineTypeId}
                                onChange={handleCreateInputChange}
                                required
                            >
                                <option value="">Selecione um tipo</option>
                                {listRoutineType.map((routineType) => (
                                    <option key={routineType.id} value={routineType.id}>
                                        {routineType.type}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>

                        <FormGroup>
                            <Label className="form-control-label" htmlFor="input-description">
                                Descreva a rotina que será realizada:
                            </Label>
                            <Input
                                className="form-control-alternative"
                                type="text"
                                name="description"
                                id="description"
                                value={newRoutine.description}
                                onChange={handleCreateInputChange}
                                required
                                placeholder="Ex: Manutenção preventiva mensal"
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label className="form-control-label" htmlFor="input-initialDate">A rotina começará no dia:</Label>
                            <Input
                                className="form-control-alternative"
                                type="date"
                                name="initialDate"
                                id="initialDate"
                                value={newRoutine.initialDate}
                                onChange={handleCreateInputChange}
                                required
                            />
                        </FormGroup>

                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-finalDate">Até o dia:</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="date"
                                        name="finalDate"
                                        id="finalDate"
                                        value={newRoutine.finalDate || ''}
                                        onChange={handleCreateInputChange}
                                        disabled={newRoutine.numberOfCharges !== null}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6" className="d-flex align-items-center">
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            checked={!!newRoutine.numberOfCharges}
                                            onChange={() => {
                                                setNewRoutine(prevRoutine => ({
                                                    ...prevRoutine,
                                                    numberOfCharges: prevRoutine.numberOfCharges === null ? 1 : null,
                                                    finalDate: prevRoutine.numberOfCharges === null ? null : prevRoutine.finalDate,
                                                }));
                                            }}
                                        />{' '}
                                        Definir número de cobranças
                                    </Label>
                                    <Label check className='mt-2'>
                                        <Input
                                            type="checkbox"
                                            checked={newRoutine.onlyBusinessDays || false}
                                            onChange={() =>
                                                setNewRoutine(prevRoutine => ({
                                                    ...prevRoutine,
                                                    onlyBusinessDays: !prevRoutine.onlyBusinessDays,
                                                    businessDayOfMonth: prevRoutine.onlyBusinessDays ? null : prevRoutine.businessDayOfMonth,
                                                }))
                                            }
                                        />{' '}
                                        Apenas dias úteis
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>

                        {newRoutine.numberOfCharges !== null && (
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="input-numberOfCharges">Quantidade de cobranças:</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="number"
                                    name="numberOfCharges"
                                    id="numberOfCharges"
                                    value={newRoutine.numberOfCharges || ''}
                                    onChange={handleCreateInputChange}
                                    disabled={newRoutine.finalDate !== null}
                                />
                            </FormGroup>
                        )}

                        <FormGroup>
                            <Label className="form-control-label" htmlFor="input-frequency">Essa rotina será realizada com a seguinte frequência:</Label>
                            <Input
                                className="form-control-alternative"
                                type="select"
                                name="frequency"
                                id="frequency"
                                value={newRoutine.frequency}
                                onChange={handleCreateInputChange}
                                required
                            >
                                <option value="">Selecione a Frequência</option>
                                <option value="0">Apenas uma vez</option>
                                <option value="1">Diária</option>
                                <option value="2">Semanal</option>
                                <option value="3">Mensal</option>
                                <option value="4">Trimestral</option>
                                <option value="5">Semestral</option>
                                <option value="6">Anual</option>
                            </Input>
                        </FormGroup>

                        {newRoutine.onlyBusinessDays && newRoutine.frequency.toString() === '3' && (
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="input-businessDayOfMonth">Dia útil do mês:</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="number"
                                    name="businessDayOfMonth"
                                    id="businessDayOfMonth"
                                    value={newRoutine.businessDayOfMonth || ''}
                                    onChange={e =>
                                        setNewRoutine(prevRoutine => ({
                                            ...prevRoutine,
                                            businessDayOfMonth: e.target.value ? parseInt(e.target.value, 10) : null,
                                        }))
                                    }
                                    placeholder="Informe o número do dia útil (ex: 1 para 1º dia útil)"
                                    min={1}
                                />
                            </FormGroup>
                        )}

                        <FormGroup>
                            <Label className="form-control-label" htmlFor="input-billingFrequency">Para envio de email automático, em quantos dias serão cobrados?</Label>
                            <Input
                                className="form-control-alternative"
                                type="number"
                                name="billingFrequency"
                                id="billingFrequency"
                                value={newRoutine.billingFrequency || 0}
                                onChange={handleCreateInputChange}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label className="form-control-label" htmlFor="input-emails">Emails associados à rotina:</Label>
                            <Input
                                className="form-control-alternative"
                                type="text"
                                name="emails"
                                id="emails"
                                value={newRoutine.emails.join(', ')}
                                onChange={(e) => setNewRoutine(prev => ({
                                    ...prev,
                                    emails: e.target.value.split(',').map(email => email.trim())
                                }))}
                                placeholder="Ex: email1@exemplo.com, email2@exemplo.com"
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label className="form-control-label" htmlFor="input-tags">Tags associadas à rotina:</Label>
                            <Input
                                className="form-control-alternative"
                                type="text"
                                name="tags"
                                id="tags"
                                value={newRoutine.tags.join(', ')}
                                onChange={(e) => setNewRoutine(prev => ({
                                    ...prev,
                                    tags: e.target.value.split(',').map(tag => tag.trim())
                                }))}
                                placeholder="Ex: tag1, tag2"
                            />
                        </FormGroup>

                        <div className="d-flex justify-content-end mt-3">
                            <Button style={{ color: "#FFF", backgroundColor: "#585858" }} type="submit" size="m">Criar Rotina</Button>
                            <Button style={{ color: "#FFF", backgroundColor: "#585858" }} onClick={() => setCreateModalOpen(!createModalOpen)} size="m" className="ml-2">Cancelar</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal isOpen={editModalOpen} toggle={() => setEditModalOpen(!editModalOpen)} backdrop="static" className="custom-modal-xl">
                <ModalHeader toggle={() => setEditModalOpen(!editModalOpen)}>Informações da rotina</ModalHeader>
                <ModalBody>
                    {editingRoutine && (
                        <>
                            <Form onSubmit={handleEditSubmit}>
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-routineType">Tipo da Rotina</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="select"
                                        name="routineTypeId"
                                        id="routineTypeId"
                                        value={editingRoutine.routineTypeId}
                                        onChange={e => setEditingRoutine({ ...editingRoutine, routineTypeId: Number(e.target.value) })}
                                        required
                                    >
                                        <option value="">Selecione um tipo</option>
                                        {listRoutineType.map((routineType) => (
                                            <option key={routineType.id} value={routineType.id}>
                                                {routineType.type}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-description">Descrição</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="description"
                                        id="description"
                                        value={editingRoutine.description}
                                        onChange={e => setEditingRoutine({ ...editingRoutine, description: e.target.value })}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-emails">Emails associados à rotina:</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="emails"
                                        id="emails"
                                        value={editingRoutine.emails.join(', ')}
                                        onChange={(e) => setEditingRoutine(prev => prev ? ({
                                            ...prev,
                                            emails: e.target.value.split(',').map(email => email.trim())
                                        }) : prev)}
                                        placeholder="Ex: email1@example.com, email2@example.com"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-tags">Tags associadas à rotina:</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="tags"
                                        id="tags"
                                        value={editingRoutine.tags.join(', ')}
                                        onChange={(e) => setEditingRoutine(prev => prev ? ({
                                            ...prev,
                                            tags: e.target.value.split(',').map(tag => tag.trim())
                                        }) : prev)}
                                        placeholder="Ex: tag1, tag2"
                                    />
                                </FormGroup>
                                <div className="d-flex justify-content-end">
                                    <Button style={{ color: "#FFF", backgroundColor: "#585858" }} type="submit" size="m">Salvar alterações</Button>
                                    <Button style={{ color: "#FFF", backgroundColor: "#585858" }} onClick={() => setEditModalOpen(!editModalOpen)} size="m">Cancelar</Button>
                                </div>
                            </Form>

                            <h5 className="mt-4">Tarefas da Rotina</h5>
                            {loading ? (
                                <LoadingSpinner />
                            ) : (
                                <Table hover responsive className="table-striped custom-table">
                                    <thead>
                                        <tr>
                                            <th>Cliente</th>
                                            <th>Rotina</th>
                                            <th>Enviar em</th>
                                            <th>Data de envio</th>
                                            <th>Dt. Robô</th>
                                            <th>Status</th>
                                            <th>Download</th>
                                            <th>Análise</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listTaxTasks.map((taxTasks: TaxTasksType, index: number) => {
                                            const formattedStatus = formatTaskStatus(Number(taxTasks.status));
                                            return (
                                                <tr key={index}>
                                                    <td>{taxTasks.razaoSocial}</td>
                                                    <td>{taxTasks.description.length > 20 ? taxTasks.description.substring(0, 20) + '...' : taxTasks.description}</td>
                                                    <td>{formatDateTime(taxTasks.shippingDate)}</td>
                                                    <td>{taxTasks.submissionDate ? formatDateTime(taxTasks.submissionDate) : 'Não enviada'}</td>
                                                    <td>{taxTasks.workerDownloadDate ? formatDateTime(taxTasks.workerDownloadDate) : 'Em espera'}</td>
                                                    <td>
                                                        <span
                                                            className="status-capsule"
                                                            style={{
                                                                backgroundColor: formattedStatus.color,
                                                                padding: '5px 10px',
                                                                borderRadius: '12px',
                                                                color: '#fff'
                                                            }}
                                                        >
                                                            {formattedStatus.label}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {taxTasks.hasDocument || Number(taxTasks.status) !== 2 ? (
                                                            <Button
                                                                className="action-btn"
                                                                onClick={() => handleDownloadFile(objUserTk.OfficeId, taxTasks.id)}
                                                                disabled={!taxTasks.hasDocument}
                                                            >
                                                                <i className="fa-solid fa-download"></i> Baixar
                                                            </Button>
                                                        ) : (
                                                            <span className="no-document">Sem documento</span>
                                                        )}
                                                    </td>
                                                    <th>
                                                        <Button onClick={() => { handleEditTaxTasks(taxTasks); setSelectedTaxTasksId(taxTasks.id); }}><i className="fa-solid fa-pen-to-square"></i></Button>
                                                    </th>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            )}
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ backgroundColor: "#585858", color: "#FFF" }}
                        onClick={() => handleDownloadAll(objUserTk.OfficeId, selectedRoutineId)}
                    >
                        Baixar Todos os Arquivos
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={editTaxTasksModalOpen} toggle={() => setEditTaxTasksModalOpen(!editTaxTasksModalOpen)} backdrop="static" size="md">
                <ModalHeader toggle={() => setEditTaxTasksModalOpen(!editTaxTasksModalOpen)}>Editar Tarefa</ModalHeader>
                <ModalBody>
                    {editingTaxTasks && (
                        <Card>
                            <CardBody>
                                <Form onSubmit={handleEditTaxTasksSubmit}>
                                    <FormGroup>
                                        <Label className="form-control-label" htmlFor="input-observation">Observação</Label>
                                        <Input
                                            className="form-control-alternative"
                                            type="textarea"
                                            name="observation"
                                            id="observation"
                                            value={editingTaxTasks.observation}
                                            onChange={e => setEditingTaxTasks({ ...editingTaxTasks, observation: e.target.value })}
                                            placeholder="Adicione uma observação sobre esta tarefa..."
                                            rows={5}
                                        />
                                    </FormGroup>
                                    <div className="d-flex justify-content-end">
                                        <Button color="success" onClick={handleApproveTask} size="md" className="mr-2">
                                            <i className="fa fa-check"></i> Aprovar Tarefa
                                        </Button>
                                        <Button color="danger" onClick={handleRejectTask} size="md" className="mr-2">
                                            <i className="fa fa-times"></i> Rejeitar Tarefa
                                        </Button>
                                        <Button color="secondary" onClick={() => setEditTaxTasksModalOpen(!editTaxTasksModalOpen)} size="md">
                                            Cancelar
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    )}
                </ModalBody>
            </Modal>

            <Modal isOpen={importModalOpen} toggle={() => setImportModalOpen(!importModalOpen)} backdrop="static" size="lg">
                <ModalHeader toggle={() => setImportModalOpen(!importModalOpen)}>Importar rotinas via arquivo</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label className="form-control-label" htmlFor="file-upload">Selecionar arquivo (.csv ou .xlsx)</Label>
                            <Input type="file" name="file" id="file-upload" accept=".csv, .xlsx" onChange={handleFileChange} />
                        </FormGroup>
                        <div className="d-flex justify-content-end mt-4">
                            <Button style={{ color: "#FFF", backgroundColor: "#17a2b8" }} onClick={handleImportSubmit} size="m">Enviar arquivo</Button>
                            <Button style={{ color: "#FFF", backgroundColor: "#d9534f" }} className="ml-2" onClick={() => setImportModalOpen(false)} size="m">Cancelar</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    );
};

export default Routine;