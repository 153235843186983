import React, { useContext, useEffect, useState } from 'react';
import {
    Container,
    Table,
    Button,
    Row,
    Col,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    InputGroup
} from 'reactstrap';
import PaginationMaterial from "../../components/PaginationMaterial/PaginationMaterial";
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Header from '../../components/Headers/Header';
import AdminNavbar from '../../components/Navbars/AdminNavbar/AdminNavbar';
import { AuthContext } from '../../context/AuthContext';
import { TaxTasksContext } from '../../context/TaxTasksContext';
import { TaxTasksType, UpdateTaxTasksType } from '../../context/hooks/useTaxTasks';
import './TaxTasks.css';
import { DownloadContext } from '../../context/DownloadContext';
import { FilesContext } from '../../context/FilesContext';
import { EmailContext } from '../../context/EmailContext';
import Swal from 'sweetalert2';
import { format, parseISO } from 'date-fns';
import useUser from '../../context/hooks/useUser';

const TaxTasks = () => {
    const { objUserTk } = useContext(AuthContext);
    const {
        listTaxTasks,
        getAllTaxTaskPaged,
        updateTaxTasks,
        exportTaxTasks,
        getTaxTaskById,
        pageCount,
    } = useContext(TaxTasksContext);
    const { handleDownloadByTaxTasksId } = useContext(DownloadContext);
    const { getFileName, fileName } = useContext(FilesContext);
    const { sendNotificationEmail } = useContext(EmailContext);
    const { getPermissionsByUser, userPermissions } = useUser();
    const [loading, setLoading] = useState(false);
    const [dataSearch, setDataSearch] = useState({
        customerName: '',
        routineDescription: '',
        startDate: '',
        endDate: '',
        status: 0,
        tag: '',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [editingTaxTasks, setEditingTaxTasks] = useState<UpdateTaxTasksType | null>(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedTaxTasksId, setSelectedTaxTasksId] = useState(0);
    const statusOptions = [
        { value: 0, label: 'Todos' },
        { value: 1, label: 'Em Aberto' },
        { value: 2, label: 'Concluído' },
        { value: 3, label: 'Atrasado' },
        { value: 4, label: 'Rejeitado' },
        { value: 5, label: 'Aprovado' }
    ];

    useEffect(() => {
        setLoading(true);
        getAllTaxTaskPaged(1, 0, objUserTk.OfficeId)
            .then(data => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao buscar tarefas:", error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        const fetchPermissions = async () => {
            if (objUserTk?.UserId) {
                const permissions = await getPermissionsByUser(objUserTk.UserId);
                console.log("permissions", permissions);
            }
        };

        fetchPermissions().catch(error => console.error("Erro ao carregar permissões:", error));
    }, [objUserTk?.UserId]);

    const onChangePaginationCommon = async (e: any, value: any) => {
        await getAllTaxTaskPaged(value, 0, objUserTk.OfficeId);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDataSearch({ ...dataSearch, [e.target.name]: e.target.value });
    };

    const handleSearchSubmit = () => {
        setLoading(true);
        getAllTaxTaskPaged(
            1,
            0,
            objUserTk.OfficeId,
            dataSearch.status,
            dataSearch.customerName,
            dataSearch.routineDescription,
            dataSearch.startDate,
            dataSearch.endDate,
            dataSearch.tag
        )
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleClearFilters = () => {
        setDataSearch({
            customerName: '',
            routineDescription: '',
            startDate: '',
            endDate: '',
            status: 0,
            tag: '',
        });
        setLoading(true);
        getAllTaxTaskPaged(1, 0, objUserTk.OfficeId)
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    };

    const handleDownloadFile = async (officeId: number, taxTasksId: number) => {
        await handleDownloadByTaxTasksId(officeId, taxTasksId);
    };

    const formatDateTime = (dateString: string) => {
        if (!dateString) return 'Data não informada';
        const date = parseISO(dateString);
        return dateString.includes('T')
            ? format(date, 'dd/MM/yyyy HH:mm')
            : format(date, 'dd/MM/yyyy');
    };

    const formatTaskStatus = (status: number) => {
        switch (status) {
            case 1:
                return { label: 'Em Aberto', color: '#007bff' };
            case 2:
                return { label: 'Concluído', color: '#28a745' };
            case 3:
                return { label: 'Atrasado', color: '#ffc107' };
            case 4:
                return { label: 'Rejeitado', color: '#dc3545' };
            case 5:
                return { label: 'Aprovado', color: '#ffcc00' }
            default:
                return { label: 'Desconhecido', color: '#6c757d' };
        }
    };

    const handleEditTaxTasks = (taxTasks: TaxTasksType) => {
        const updateData: UpdateTaxTasksType = {
            id: taxTasks.id,
            observation: taxTasks.observation,
        };
        setEditingTaxTasks(updateData);
        setEditModalOpen(true);
    };

    const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editingTaxTasks) {
            updateTaxTasks(editingTaxTasks)
                .then(updatedTaxTasks => {
                    if (updatedTaxTasks) {
                        handleCloseEditModal();
                        getAllTaxTaskPaged(1, 0, objUserTk.OfficeId);
                        setEditingTaxTasks(null);
                    } else {
                        console.error("Erro ao atualizar tarefa.");
                    }
                })
                .catch(error => {
                    console.error("Erro ao atualizar tarefa:", error);
                });
        }
    };

    const handleCloseEditModal = () => {
        setEditModalOpen(false);
        Swal.fire({
            title: 'Deseja enviar um e-mail para notificar o cliente?',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            icon: 'question'
        }).then(async (result) => {
            if (result.isConfirmed) {                
                if (!editingTaxTasks || !editingTaxTasks.id) {
                    Swal.fire("Erro", "Nenhuma tarefa válida foi selecionada para notificação.", "error");
                    return;
                }
    
                try {
                    const taskDetails = await getTaxTaskById(editingTaxTasks.id);
    
                    if (!taskDetails || !taskDetails.routine) {
                        Swal.fire("Erro", "Não foi possível obter os detalhes da tarefa ou rotina.", "error");
                        return;
                    }
    
                    const routineEmails = taskDetails.routine.emails;
                    if (!routineEmails || routineEmails.length === 0) {
                        Swal.fire("Aviso", "A rotina não possui e-mails configurados.", "warning");
                        return;
                    }
    
                    const nomeTarefa = taskDetails.routine.description || "Tarefa sem descrição";
    
                    const emailData = {
                        from: "info@b7inovacao.com.br",
                        to: routineEmails,
                        subject: "Atualização da tarefa",
                        text: `Informamos que a tarefa "${nomeTarefa}" foi atualizada.`,
                        html: `<p>Informamos que a tarefa <strong>${nomeTarefa}</strong> foi atualizada.</p>`,
                        templateId: "",
                    };
    
                    const success = await sendNotificationEmail(emailData);
    
                    if (success) {
                        Swal.fire("Sucesso", "E-mail enviado aos destinatários com sucesso.", "success");
                    } else {
                        Swal.fire("Erro", "Não foi possível enviar o e-mail aos destinatários.", "error");
                    }
                } catch (error) {
                    Swal.fire("Erro", "Ocorreu um erro ao buscar os detalhes ou enviar o e-mail.", "error");
                    console.error("Erro ao processar a notificação por e-mail:", error);
                }
            }
        });
    };

    const handleRejectTask = () => {
        if (editingTaxTasks) {
            const rejectedTask = { ...editingTaxTasks, status: 4 };
            updateTaxTasks(rejectedTask)
                .then(updatedTaxTasks => {
                    if (updatedTaxTasks) {
                        handleCloseEditModal();
                        getAllTaxTaskPaged(1, 0, objUserTk.OfficeId);
                        setEditingTaxTasks(null);
                    } else {
                        console.error("Erro ao rejeitar tarefa.");
                    }
                })
                .catch(error => {
                    console.error("Erro ao rejeitar tarefa:", error);
                });
        }
    };

    const handleApproveTask = () => {
        if (editingTaxTasks) {
            const approvedTask = { ...editingTaxTasks, status: 5 };
            updateTaxTasks(approvedTask)
                .then(updatedTaxTasks => {
                    if (updatedTaxTasks) {
                        handleCloseEditModal();
                        getAllTaxTaskPaged(1, 0, objUserTk.OfficeId);
                        setEditingTaxTasks(null);
                    } else {
                        console.error("Erro ao aprovar tarefa.");
                    }
                })
                .catch(error => {
                    console.error("Erro ao aprovar tarefa:", error);
                });
        }
    };

    const handleExport = () => {
        exportTaxTasks(
            1,
            0,
            objUserTk.OfficeId,
            dataSearch.status,
            dataSearch.customerName,
            dataSearch.routineDescription,
            dataSearch.startDate,
            dataSearch.endDate,
            dataSearch.tag
        );
    };

    return (
        <>
            <LoadingSpinner />
            <Header />
            <AdminNavbar
                brandText={"Gerenciar Tarefas"}
                search={false}
                titleSearch={""}
            />

            <Container style={{ paddingLeft: 0, paddingRight: 0 }} className="mt-5">
                <Row>
                    <Col xs="12">
                        <Form className="d-flex search-form">
                            <FormGroup>
                                <Label for="customerName" className='mb-0'>Cliente</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="text"
                                    name="customerName"
                                    id="customerName"
                                    placeholder="Buscar por cliente"
                                    value={dataSearch.customerName}
                                    onChange={handleSearchChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="routineDescription" className='mb-0'>Rotina</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="text"
                                    name="routineDescription"
                                    id="routineDescription"
                                    placeholder="Descrição da rotina"
                                    value={dataSearch.routineDescription}
                                    onChange={handleSearchChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="status" className='mb-0'>Status</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="select"
                                    name="status"
                                    id="status"
                                    value={dataSearch.status}
                                    onChange={handleSearchChange}
                                >
                                    {statusOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="startDate" className='mb-0'>Data Inicial</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="date"
                                    name="startDate"
                                    id="startDate"
                                    placeholder="Data inicial"
                                    value={dataSearch.startDate}
                                    onChange={handleSearchChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="endDate" className='mb-0'>Data Final</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="date"
                                    name="endDate"
                                    id="endDate"
                                    placeholder="Data final"
                                    value={dataSearch.endDate}
                                    onChange={handleSearchChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="tag" className='mb-0'>Tag</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="text"
                                    name="tag"
                                    id="tag"
                                    placeholder="Buscar por tag"
                                    value={dataSearch.tag}
                                    onChange={handleSearchChange}
                                />
                            </FormGroup>
                        </Form>
                    </Col>

                    <Col xs="12" className="d-flex justify-content-end mt--4">
                        <Button className="search-btn me-2" onClick={handleSearchSubmit}>Buscar</Button>
                        <Button className="clear-btn me-2 btn-danger" onClick={handleClearFilters}>Limpar</Button>
                        <Button className="export-btn" onClick={handleExport}>Exportar</Button>
                    </Col>
                </Row>

                {userPermissions.includes(2) ? (
                    <Row className="mt-6">
                        <Col>
                            <Table hover responsive className="table-striped custom-table">
                                <thead>
                                    <tr>
                                        <th>Cliente</th>
                                        {/* <th>CNPJ</th> */}
                                        <th>Rotina</th>
                                        <th>Data de cobrança</th>
                                        <th>Enviar em</th>
                                        <th>Dt. Robô</th>
                                        <th>Status</th>
                                        <th>Tags</th>
                                        <th>Download</th>
                                        <th>Análise</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listTaxTasks.map((taxTasks: TaxTasksType, index: number) => {
                                        const formattedStatus = formatTaskStatus(Number(taxTasks.status));
                                        return (
                                            <tr key={index}>
                                                <td>{taxTasks.razaoSocial}</td>
                                                {/* <td>{taxTasks.routine.customer.cnpj}</td> */}
                                                <td>{taxTasks.description.length > 20 ? taxTasks.description.substring(0, 20) + '...' : taxTasks.description}</td>
                                                <td>{formatDateTime(taxTasks.shippingDate)}</td>
                                                <td>{taxTasks.submissionDate ? formatDateTime(taxTasks.submissionDate) : 'Não enviada'}</td>
                                                <td>{taxTasks.workerDownloadDate ? formatDateTime(taxTasks.workerDownloadDate) : 'Em espera'}</td>
                                                <td>
                                                    <span
                                                        className="status-capsule"
                                                        style={{ backgroundColor: formattedStatus.color }}
                                                    >
                                                        {formattedStatus.label}
                                                    </span>
                                                </td>
                                                <td>
                                                    {taxTasks.tags.map((tag, index) => (
                                                        <span key={index} className="tag">{tag}</span>
                                                    ))}
                                                </td>
                                                <td>
                                                    {taxTasks.hasDocument || Number(taxTasks.status) !== 2 ? (
                                                        <Button
                                                            className="action-btn"
                                                            onClick={() => handleDownloadFile(objUserTk.OfficeId, taxTasks.id)}
                                                            disabled={!taxTasks.hasDocument}
                                                        >
                                                            <i className="fa-solid fa-download"></i> Baixar
                                                        </Button>
                                                    ) : (
                                                        <span className="no-document">Sem documento</span>
                                                    )}
                                                </td>
                                                <td>
                                                    <Button onClick={() => { handleEditTaxTasks(taxTasks); setSelectedTaxTasksId(taxTasks.id); }}>
                                                        <i className="fa-solid fa-pen-to-square"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-end mt-3">
                                {pageCount && (
                                    <div className="d-flex justify-content-center mt-4">
                                        <PaginationMaterial
                                            onChange={(e: any, value: any) => onChangePaginationCommon(e, value)}
                                            count={pageCount}
                                        />
                                    </div>
                                )}
                                {listTaxTasks.length === 0 && (
                                    <div style={{ color: "red" }}>NÃO FOI ENCONTRADO NENHUM REGISTRO</div>
                                )}
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Row className="mt-4">
                        <Col>
                            <div style={{ color: "red", textAlign: "center" }}>
                                Você não tem permissão para visualizar as tarefas.
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>

            <Modal isOpen={editModalOpen} toggle={() => setEditModalOpen(!editModalOpen)} backdrop="static" size="md">
                <ModalHeader toggle={() => setEditModalOpen(!editModalOpen)}>Editar Tarefa</ModalHeader>
                <ModalBody>
                    {editingTaxTasks && (
                        <Form onSubmit={handleEditSubmit}>
                            <FormGroup>
                                <Label className="form-control-label" htmlFor="input-observation">Observação</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="textarea"
                                    name="observation"
                                    id="observation"
                                    value={editingTaxTasks.observation}
                                    onChange={e => setEditingTaxTasks({ ...editingTaxTasks, observation: e.target.value })}
                                    placeholder="Adicione uma observação sobre esta tarefa..."
                                    rows={5}
                                />
                            </FormGroup>
                            <div className="d-flex justify-content-end">
                                <Button color="success" onClick={handleApproveTask} size="md" className="mr-2">
                                    <i className="fa fa-check"></i> Aprovar Tarefa
                                </Button>
                                <Button color="danger" onClick={handleRejectTask} size="md" className="mr-2">
                                    <i className="fa fa-times"></i> Rejeitar Tarefa
                                </Button>
                                <Button color="secondary" onClick={() => setEditModalOpen(!editModalOpen)} size="md">
                                    Cancelar
                                </Button>
                            </div>
                        </Form>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default TaxTasks;
