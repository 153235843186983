import React, { useEffect, useState, useContext } from "react";
import {
    Container,
    Button,
    Table,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import { useHolidayContext } from "../../context/HolidayContext";
import Header from "../../components/Headers/Header";
import AdminNavbar from "../../components/Navbars/AdminNavbar/AdminNavbar";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import PaginationMaterial from "../../components/PaginationMaterial/PaginationMaterial";
import useUser from '../../context/hooks/useUser';
import { AuthContext } from '../../context/AuthContext';
import "./Holiday.css";

export default function HolidayView() {
    const {
        getAllHolidaysPaged,
        createHoliday,
        updateHoliday,
        deleteHoliday,
        listHolidays,
        pageCount,
    } = useHolidayContext();
    const { objUserTk } = useContext(AuthContext);
    const { getPermissionsByUser, userPermissions } = useUser();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [searchFilters, setSearchFilters] = useState<{
        name: string;
        category: string;
        startDate: string;
        endDate: string;
    }>({
        name: "",
        category: "",
        startDate: "",
        endDate: "",
    });
    const [newHoliday, setNewHoliday] = useState<{
        officeId: number;
        date: string;
        name: string;
        category: number;
    }>({
        officeId: objUserTk?.OfficeId || 0,
        date: "",
        name: "",
        category: 0,
    });
    const [editingHoliday, setEditingHoliday] = useState<{
        id: number;
        date: string;
        name: string;
        category: number;
    } | null>(null);

    useEffect(() => {
        loadHolidays();
    }, [currentPage]);

    useEffect(() => {
        const fetchPermissions = async () => {
            if (objUserTk?.UserId) {
                const permissions = await getPermissionsByUser(objUserTk.UserId);
            }
        };

        fetchPermissions().catch(error => console.error("Erro ao carregar permissões:", error));
    }, [objUserTk?.UserId]);

    async function loadHolidays() {
        await getAllHolidaysPaged(currentPage, {
            officeId: objUserTk?.OfficeId || 0,
            name: searchFilters.name,
            category: searchFilters.category ? parseInt(searchFilters.category) : undefined,
            startDate: searchFilters.startDate,
            endDate: searchFilters.endDate,
        });
    }

    function handleSearchChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { name, value } = e.target;
        setSearchFilters((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    async function clearFilters() {
        await setSearchFilters({ name: "", category: "", startDate: "", endDate: "" });
        await getAllHolidaysPaged(1, { officeId: objUserTk?.OfficeId || 0 });
    }

    function handleCreateInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        const { name, value } = e.target;
        setNewHoliday((prev) => ({
            ...prev,
            [name]: name === "category" ? parseInt(value) : value,
        }));
    }

    async function handleCreateSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        await createHoliday(newHoliday);
        setCreateModalOpen(false);
        setNewHoliday({ officeId: objUserTk.OfficeId, date: "", name: "", category: 0 });
        await loadHolidays();
    }

    function handleEditInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
        if (!editingHoliday) return;
        const { name, value } = e.target;
        setEditingHoliday({
            ...editingHoliday,
            [name]: name === "category" ? parseInt(value) : value,
        });
    }

    async function handleEditSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (!editingHoliday) return;
        await updateHoliday(editingHoliday);
        setEditModalOpen(false);
        setEditingHoliday(null);
        await loadHolidays();
    }

    function getWeekday(dateStr: string): string {
        const days = [
            "Domingo",
            "Segunda-feira",
            "Terça-feira",
            "Quarta-feira",
            "Quinta-feira",
            "Sexta-feira",
            "Sábado",
        ];
        const [year, month, day] = dateStr.split("-").map(Number);
        const d = new Date(year, (month || 1) - 1, day || 1);
        return days[d.getDay()];
    }

    return (
        <>
            <LoadingSpinner />
            <Header />
            <AdminNavbar brandText={"Feriados"} search={false} titleSearch={""} />

            <Container className="mt-5">
                <Row>
                    <Col xs="12">
                        <Form className="d-flex search-form">
                            <FormGroup className="mr-3">
                                <Label for="name">Nome</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    placeholder="Buscar por nome"
                                    value={searchFilters.name}
                                    onChange={handleSearchChange}
                                    className="form-control-alternative"
                                />
                            </FormGroup>

                            <FormGroup className="mr-3">
                                <Label for="category">Categoria</Label>
                                <Input
                                    type="select"
                                    name="category"
                                    value={searchFilters.category}
                                    onChange={handleSearchChange}
                                    className="form-control-alternative"
                                >
                                    <option value="">Categoria</option>
                                    <option value="1">Federal/Estadual</option>
                                    <option value="2">Municipal</option>
                                </Input>
                            </FormGroup>
                            <FormGroup className="mr-3">
                                <Label for="startDate">Data Inicial</Label>
                                <Input
                                    type="date"
                                    name="startDate"
                                    placeholder="Data inicial"
                                    value={searchFilters.startDate}
                                    onChange={handleSearchChange}
                                    className="form-control-alternative"
                                />
                            </FormGroup>
                            <FormGroup className="mr-3">
                                <Label for="endDate">Data Final</Label>
                                <Input
                                    type="date"
                                    name="endDate"
                                    placeholder="Data final"
                                    value={searchFilters.endDate}
                                    onChange={handleSearchChange}
                                    className="form-control-alternative"
                                />
                            </FormGroup>
                            <Button className="search-btn mr-2" onClick={loadHolidays}>Pesquisar</Button>
                            <Button className="clear-btn" onClick={clearFilters}>Limpar</Button>
                        </Form>
                        {userPermissions.includes(1) && (
                            <Button className="btn-criar-feriado" onClick={() => setCreateModalOpen(true)}>
                                Criar Feriado
                            </Button>
                        )}
                    </Col>
                </Row>

                {userPermissions.includes(2) ? (
                    <Row className="mt-2">
                        <Col>
                            <Table hover responsive className="custom-table">
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Dia da Semana</th>
                                        <th>Nome</th>
                                        <th>Categoria</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listHolidays.map((holiday) => (
                                        <tr key={holiday.id}>
                                            <td>{holiday.date}</td>
                                            <td>{getWeekday(holiday.date)}</td>
                                            <td>{holiday.name}</td>
                                            <td>{holiday.category === 1 ? "Federal/Estadual" : "Municipal"}</td>
                                            <td className="action-buttons">
                                                {userPermissions.includes(3) && (
                                                    <Button onClick={() => { setEditingHoliday(holiday); setEditModalOpen(true); }}><i className="fa-solid fa-pen-to-square"></i></Button>
                                                )}
                                                {userPermissions.includes(4) && (
                                                    <Button onClick={() => deleteHoliday(holiday.id)} color="danger"><i className="fa-solid fa-trash"></i></Button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-end mt-4">
                                <PaginationMaterial
                                    count={pageCount}
                                    onChange={(e, value) => setCurrentPage(value)}
                                />
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Row className="mt-4">
                        <Col>
                            <div style={{ color: "red", textAlign: "center" }}>
                                Você não tem permissão para visualizar as rotinas.
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
            <Modal isOpen={createModalOpen} toggle={() => setCreateModalOpen(!createModalOpen)}>
                <ModalHeader toggle={() => setCreateModalOpen(!createModalOpen)}>
                    Criar Feriado
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleCreateSubmit}>
                        <FormGroup>
                            <Label for="date">Data</Label>
                            <Input
                                type="date"
                                name="date"
                                id="date"
                                className="form-control-alternative"
                                value={newHoliday.date}
                                onChange={handleCreateInputChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="name">Nome</Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                className="form-control-alternative"
                                value={newHoliday.name}
                                onChange={handleCreateInputChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="category">Categoria</Label>
                            <Input
                                type="select"
                                name="category"
                                id="category"
                                className="form-control-alternative"
                                value={newHoliday.category.toString()}
                                onChange={handleCreateInputChange}
                                required
                            >
                                <option value="0">Selecione</option>
                                <option value="1">Federal/Estadual</option>
                                <option value="2">Municipal</option>
                            </Input>
                        </FormGroup>
                        <ModalFooter>
                            <Button type="submit" style={{ color: "#FFF", backgroundColor: "#585858" }}>
                                Criar
                            </Button>
                            <Button style={{ color: "#FFF", backgroundColor: "#585858" }} onClick={() => setCreateModalOpen(false)}>
                                Cancelar
                            </Button>
                        </ModalFooter>
                    </Form>
                </ModalBody>
            </Modal>
            <Modal isOpen={editModalOpen} toggle={() => setEditModalOpen(!editModalOpen)}>
                <ModalHeader toggle={() => setEditModalOpen(!editModalOpen)}>
                    Editar Feriado
                </ModalHeader>
                <ModalBody>
                    {editingHoliday && (
                        <Form onSubmit={handleEditSubmit}>
                            <FormGroup>
                                <Label for="date">Data</Label>
                                <Input
                                    type="date"
                                    name="date"
                                    id="date"
                                    className="form-control-alternative"
                                    value={editingHoliday.date}
                                    onChange={handleEditInputChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="name">Nome</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="form-control-alternative"
                                    value={editingHoliday.name}
                                    onChange={handleEditInputChange}
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="category">Categoria</Label>
                                <Input
                                    type="select"
                                    name="category"
                                    id="category"
                                    className="form-control-alternative"
                                    value={editingHoliday.category.toString()}
                                    onChange={handleEditInputChange}
                                    required
                                >
                                    <option value="0">Selecione</option>
                                    <option value="1">Federal/Estadual</option>
                                    <option value="2">Municipal</option>
                                </Input>
                            </FormGroup>
                            <ModalFooter>
                                <Button style={{ color: "#FFF", backgroundColor: "#585858" }} type="submit">
                                    Salvar
                                </Button>
                                <Button style={{ color: "#FFF", backgroundColor: "#585858" }} onClick={() => setEditModalOpen(false)}>
                                    Cancelar
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
}