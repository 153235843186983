import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface UserTypeType {
    id: number;
    type: string;    
    permissions: number[];
    office: {
        id: number;
        name: string;
    };
}

export interface CreateUserTypeType {
    type: string;
    officeId: number;
    permissions: number[];
}

export interface UpdateUserTypeType {
    id: number;
    type: string;
    permissions: number[];
}

export default function useUserType() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const { handleLogout, objUserTk } = useContext(AuthContext);
    const [listUserType, setListUserType] = useState<UserTypeType[]>([]);
    const [pageCount, setPageCount] = useState<number>(0);
    const [initialListUserType, setInitialListUserType] = useState<UserTypeType[]>([]);
    const [userType, setUserType] = useState<UserTypeType>({
        id: 0,
        type: "",
        office: {
            id: 0,
            name: "",
        },
        permissions: [],
    });

    function createUserType(createUserTypeType: CreateUserTypeType): Promise<CreateUserTypeType | null> {
        setLoading(true);
        return api
            .post(`/UserType/create`, createUserTypeType)
            .then((response) => {
                const createdUserType: CreateUserTypeType = response.data;
                showToast("success", "Tipo de usuário criada com sucesso.");
                setLoading(false);
                return createdUserType;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao criar tipo de usuário. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao criar tipo de usuário:", error);
                return null;
            });
    }

    function getAllUserTypePaged(
        pageNumber: number,
        officeId?: number,
    ): Promise<UserTypeType[] | null> {
        setLoading(true);
        let url = `/UserType/getAllUserTypePaged?pageNumber=${pageNumber}&pageSize=9`;

        if (officeId) {
            url += `&officeId=${officeId}&routineId=0`;
        }

        return api
            .get(url)
            .then((response) => {
                setListUserType(response.data.result);
                setInitialListUserType(response.data.result);
                setPageCount(response.data.pageCount);
                setLoading(false);
                return response.data.result;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao carregar tipo de usuário. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao carregar tipo de usuário:", error);
                return null;
            });
    }

    function updateUserType(updateUserTypeData: UpdateUserTypeType): Promise<UpdateUserTypeType | null> {
        setLoading(true);
        return api
            .put(`/UserType/${updateUserTypeData.id}`, updateUserTypeData)
            .then((response) => {
                showToast("success", "Tipo de usuário atualizado com sucesso.");
                setLoading(false);
                return response.data;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao atualizar tipo de usuário. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao atualizar tipo de usuário:", error);
                return null;
            });
    }

    function deleteUserType(userTypeId: number): Promise<UserTypeType | null> {
        setLoading(true);
        return api
            .delete(`/UserType/${userTypeId}`)
            .then(() => {
                setUserType({
                    id: 0,
                    type: "",
                    office: {
                        id: 0,
                        name: "",
                    },
                    permissions: [],
                });
                showToast("success", "Tipo de usuário deletado com sucesso.");
                setLoading(false);
                return null;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao deletar tipo de usuário. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao deletar tipo de usuário:", error);
                return null;
            });
    }

    return {
        createUserType,
        getAllUserTypePaged,
        updateUserType,
        deleteUserType,
        setListUserType,
        listUserType,
        setPageCount,
        pageCount,
        setInitialListUserType,
        initialListUserType,
        setUserType,
        userType,
    };
}