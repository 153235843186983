import React, { useContext, useEffect, useState } from 'react';
import { Container, Table, Button, Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';
import PaginationMaterial from "../../components/PaginationMaterial/PaginationMaterial";
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Header from '../../components/Headers/Header';
import AdminNavbar from '../../components/Navbars/AdminNavbar/AdminNavbar';
import './Routine.css';
import { AuthContext } from '../../context/AuthContext';
import { UserTypeContext } from '../../context/UserTypeContext';
import { UserTypeType, CreateUserTypeType, UpdateUserTypeType } from '../../context/hooks/useUserType';
import useUser from '../../context/hooks/useUser';

const UserType = () => {
    const { objUserTk } = useContext(AuthContext);
    const { listUserType, pageCount, getAllUserTypePaged, createUserType, updateUserType, deleteUserType } = useContext(UserTypeContext);
    const { getPermissionsByUser, userPermissions } = useUser();

    const [selectedUserTypeId, setSelectedUserTypeId] = useState(0);
    const [newUserType, setNewUserType] = useState<CreateUserTypeType>({
        type: '',
        officeId: objUserTk.OfficeId,
        permissions: [],
    });

    const [permissions, setPermissions] = useState<number[]>([]);
    const [editingPermissions, setEditingPermissions] = useState<number[]>([]);

    const permissionOptions = [
        { label: "Adicionar", value: 1 },
        { label: "Visualizar", value: 2 },
        { label: "Atualizar", value: 3 },
        { label: "Deletar", value: 4 },
    ];

    const getPermissionColor = (permission: any) => {
        switch (permission) {
            case 1:
                return '#4caf50';
            case 2:
                return '#ff9800';
            case 3:
                return '#2196f3';
            case 4:
                return '#f44336';
            default:
                return '#9e9e9e';
        }
    };

    const handlePermissionChange = (value: number) => {
        setPermissions((prev) =>
            prev.includes(value) ? prev.filter((p) => p !== value) : [...prev, value]
        );
    };

    const handleEditingPermissionChange = (value: number) => {
        setEditingPermissions((prev) =>
            prev.includes(value) ? prev.filter((p) => p !== value) : [...prev, value]
        );
    };

    const [editingUserType, setEditingUserType] = useState<UpdateUserTypeType | null>(null);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const [filters, setFilters] = useState({
        type: '',
    });

    useEffect(() => {
        setLoading(true);
        getAllUserTypePaged(1, objUserTk.OfficeId)
            .then(data => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao buscar tipos de usuários:", error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        const fetchPermissions = async () => {
            if (objUserTk?.UserId) {
                const permissions = await getPermissionsByUser(objUserTk.UserId);
            }
        };

        fetchPermissions().catch(error => console.error("Erro ao carregar permissões:", error));
    }, [objUserTk?.UserId]);

    const onChangePaginationCommon = async (e: any, value: any) => {
        await getAllUserTypePaged(value, objUserTk.OfficeId);
    };

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSearch = () => {
        setLoading(true);
        getAllUserTypePaged(
            1,
            objUserTk.OfficeId,
        )
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao aplicar filtros:", error);
                setLoading(false);
            });
    };

    const handleClearFilters = () => {
        getAllUserTypePaged(
            1,
            objUserTk.OfficeId,
        )
            .then(() => {
                setLoading(false);
            })
            .catch(error => {
                console.error("Erro ao liimpar filtros:", error);
                setLoading(false);
            });
    };

    const handleCreateInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setNewUserType(prevUserType => ({
            ...prevUserType,
            [name]: value === '' ? null : value
        }));
    };

    const handleCreateUserType = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const payload = {
            ...newUserType,
            type: newUserType.type,
            officeId: Number(newUserType.officeId),
            permissions
        };

        createUserType(payload)
            .then(createdUserType => {
                if (createdUserType) {
                    setCreateModalOpen(false);
                    setNewUserType({
                        type: '',
                        officeId: objUserTk.OfficeId,
                        permissions: [],
                    });
                    getAllUserTypePaged(1, objUserTk.OfficeId);
                } else {
                    console.error("Erro ao criar tipo de usuário.");
                }
            })
            .catch(error => {
                console.error("Erro ao criar tipo de usuário:", error);
            });
    };

    const handleEditUserType = (userType: UserTypeType) => {
        setEditingPermissions(userType.permissions);
        const updateData: UpdateUserTypeType = {
            id: userType.id,
            type: userType.type,
            permissions: userType.permissions
        };
        setEditingUserType(updateData);
        setEditModalOpen(true);
    };

    // const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();
    //     if (editingUserType) {
    //         updateUserType(editingUserType)
    //             .then(updatedUserType => {
    //                 if (updatedUserType) {
    //                     setEditModalOpen(false);
    //                     getAllUserTypePaged(1, objUserTk.OfficeId);
    //                     setEditingUserType(null);
    //                 } else {
    //                     console.error("Erro ao atualizar tipo de usuário.");
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error("Erro ao atualizar o tipo da usuário:", error);
    //             });
    //     }
    // };

    const handleEditSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editingUserType) {
            const updatedData = {
                ...editingUserType,
                permissions: editingPermissions
            };
            updateUserType(updatedData)
                .then(updatedUserType => {
                    if (updatedUserType) {
                        setEditModalOpen(false);
                        getAllUserTypePaged(1, objUserTk.OfficeId);
                        setEditingUserType(null);
                    } else {
                        console.error("Erro ao atualizar tipo de usuário.");
                    }
                })
                .catch(error => {
                    console.error("Erro ao atualizar o tipo da usuário:", error);
                });
        }
    };

    const handleDeleteUserType = (userTypeId: number) => {
        if (window.confirm("Tem certeza que deseja excluir este tipo de usuário?")) {
            deleteUserType(userTypeId)
                .then(deletedUserType => {
                    if (deletedUserType) {
                        getAllUserTypePaged(1, objUserTk.OfficeId);
                    } else {
                        console.error("Erro ao excluir tipo de usuário.");
                    }
                    getAllUserTypePaged(1, objUserTk.OfficeId);
                })
                .catch(error => {
                    console.error("Erro ao excluir tipo de usuário:", error);
                });
        }
    };

    return (
        <>
            <LoadingSpinner />
            <Header />
            <AdminNavbar
                brandText={"Tipos de Usuários"}
                search={false}
                titleSearch={""}
            />

            <Container className="mt-5">
                <Row>
                    <Col xs="12">
                        {/* <Form className="d-flex search-form">
                            <FormGroup className="mr-3">
                                <Label for="description">Rotina</Label>
                                <Input
                                    className="form-control-alternative"
                                    type="text"
                                    name="description"
                                    id="description"
                                    value={filters.description}
                                    placeholder='Descrição da rotina'
                                    onChange={handleFilterChange}
                                />
                            </FormGroup>
                            <Button className="search-btn" onClick={handleSearch}>Pesquisar</Button>
                            <Button className="clear-btn" onClick={handleClearFilters}>Limpar</Button>
                        </Form> */}
                        {userPermissions.includes(1) && (
                            <Button
                                style={{ color: "#FFF", backgroundColor: "#585858", float: "left" }}
                                className="btn-filter"
                                onClick={() => setCreateModalOpen(true)}
                            >
                                Adicionar tipo de usuário
                            </Button>
                        )}
                    </Col>
                </Row>

                {userPermissions.includes(2) ? (
                    <Row className="mt-4">
                        <Col>
                            <Table hover responsive className="table-striped custom-table">
                                <thead>
                                    <tr>
                                        <th>Tipo</th>
                                        <th>Permissões</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listUserType.map((userType: UserTypeType, index: number) => {
                                        // const userPermissions = userType.permissions?.length > 0 ? userType.permissions : [1, 2, 3, 4];
                                        return (
                                            <tr key={index}>
                                                <td>{userType.type}</td>
                                                <td>
                                                    {/* {userPermissions.map((permission) => {
                                                    const permissionLabel = permissionOptions.find(option => option.value === permission)?.label;
                                                    const permissionColor = getPermissionColor(permission);
                                                    return (
                                                        <div
                                                            key={permission}
                                                            style={{
                                                                display: 'inline-block',
                                                                padding: '5px 10px',
                                                                borderRadius: '20px',
                                                                backgroundColor: permissionColor,
                                                                color: '#fff',
                                                                marginRight: '8px',
                                                                fontSize: '14px',
                                                            }}
                                                        >
                                                            {permissionLabel}
                                                        </div>
                                                    );
                                                })} */}
                                                    {userType.permissions === null ? (
                                                        permissionOptions.map((option) => {
                                                            const permissionLabel = option.label;
                                                            const permissionColor = getPermissionColor(option.value);

                                                            return (
                                                                <div
                                                                    key={option.value}
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        padding: '5px 10px',
                                                                        borderRadius: '20px',
                                                                        backgroundColor: permissionColor,
                                                                        color: '#fff',
                                                                        marginRight: '8px',
                                                                        fontSize: '14px',
                                                                    }}
                                                                >
                                                                    {permissionLabel}
                                                                </div>
                                                            );
                                                        })
                                                    ) : userType.permissions.length > 0 ? (
                                                        userType.permissions.map((permission) => {
                                                            const permissionLabel = permissionOptions.find(option => option.value === permission)?.label;
                                                            const permissionColor = getPermissionColor(permission);

                                                            return (
                                                                <div
                                                                    key={permission}
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        padding: '5px 10px',
                                                                        borderRadius: '20px',
                                                                        backgroundColor: permissionColor,
                                                                        color: '#fff',
                                                                        marginRight: '8px',
                                                                        fontSize: '14px',
                                                                    }}
                                                                >
                                                                    {permissionLabel}
                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                        <div style={{ fontSize: '14px', color: 'gray' }}>Sem permissões</div>
                                                    )}
                                                </td>
                                                <td>
                                                    {userPermissions.includes(3) && (
                                                        <Button onClick={() => { handleEditUserType(userType); setSelectedUserTypeId(userType.id); }}><i className="fa-solid fa-pen-to-square"></i></Button>
                                                    )}
                                                    {userPermissions.includes(4) && (
                                                        <Button onClick={() => handleDeleteUserType(userType.id)} color="danger"><i className="fa-solid fa-trash"></i></Button>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-end mt-3">
                                {pageCount && (
                                    <div className="d-flex justify-content-center mt-4">
                                        <PaginationMaterial
                                            onChange={(e: any, value: any) => onChangePaginationCommon(e, value)}
                                            count={pageCount}
                                        />
                                    </div>
                                )}
                                {listUserType.length === 0 && (
                                    <div style={{ color: "red" }}>NÃO FOI ENCONTRADO NENHUM REGISTRO</div>
                                )}
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <Row className="mt-4">
                        <Col>
                            <div style={{ color: "red", textAlign: "center" }}>
                                Você não tem permissão para visualizar os tipos de usuário.
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>

            <Modal isOpen={createModalOpen} toggle={() => setCreateModalOpen(!createModalOpen)} backdrop="static" size="lg">
                <ModalHeader toggle={() => setCreateModalOpen(!createModalOpen)}>Adicionar novo tipo de usuário</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleCreateUserType}>
                        <FormGroup>
                            <Label className="form-control-label" htmlFor="input-description">
                                Tipo de usuário:
                            </Label>
                            <Input
                                className="form-control-alternative"
                                type="text"
                                name="type"
                                id="type"
                                value={newUserType.type}
                                onChange={handleCreateInputChange}
                                required
                                placeholder="Ex: Administrador, Usuário Comum, etc..."
                            />
                        </FormGroup>
                        {/* <FormGroup>
                            <Label className="form-control-label" htmlFor="permissions">
                                Permissões:
                            </Label>
                            <div>
                                {permissionOptions.map((option) => (
                                    <label key={option.value} style={{ marginRight: '10px' }}>
                                        <input
                                            type="checkbox"
                                            value={option.value}
                                            checked={permissions.includes(option.value)}
                                            onChange={() => handlePermissionChange(option.value)}
                                        />
                                        {option.label}
                                    </label>
                                ))}
                            </div>
                        </FormGroup> */}
                        <FormGroup>
                            <Label className="form-control-label" htmlFor="permissions">
                                Permissões:
                            </Label>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                                {permissionOptions.map((option) => (
                                    <label key={option.value} style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="checkbox"
                                            value={option.value}
                                            checked={permissions.includes(option.value)}
                                            onChange={() => handlePermissionChange(option.value)}
                                            style={{ marginRight: '8px' }}
                                        />
                                        {option.label}
                                    </label>
                                ))}
                            </div>
                        </FormGroup>

                        <div className="d-flex justify-content-end mt-3">
                            <Button style={{ color: "#FFF", backgroundColor: "#585858" }} type="submit" size="m">Criar Tipo de Usuário</Button>
                            <Button style={{ color: "#FFF", backgroundColor: "#585858" }} onClick={() => setCreateModalOpen(!createModalOpen)} size="m" className="ml-2">Cancelar</Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>

            <Modal isOpen={editModalOpen} toggle={() => setEditModalOpen(!editModalOpen)} backdrop="static" className="custom-modal-xl">
                <ModalHeader toggle={() => setEditModalOpen(!editModalOpen)}>Editar informações do tipo de usuário</ModalHeader>
                <ModalBody>
                    {editingUserType && (
                        <>
                            <Form onSubmit={handleEditSubmit}>
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="input-description">Tipo do usuário</Label>
                                    <Input
                                        className="form-control-alternative"
                                        type="text"
                                        name="type"
                                        id="type"
                                        value={editingUserType.type}
                                        onChange={e => setEditingUserType({ ...editingUserType, type: e.target.value })}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label className="form-control-label" htmlFor="permissions">
                                        Permissões:
                                    </Label>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                                        {permissionOptions.map(option => (
                                            <label key={option.value} style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    type="checkbox"
                                                    // checked={editingPermissions.includes(option.value)}
                                                    checked={editingPermissions ? editingPermissions.includes(option.value) : true}
                                                    onChange={() => handleEditingPermissionChange(option.value)}
                                                    style={{ marginRight: '8px' }}
                                                />
                                                {option.label}
                                            </label>
                                        ))}
                                    </div>
                                </FormGroup>
                                <div className="d-flex justify-content-end">
                                    <Button style={{ color: "#FFF", backgroundColor: "#585858" }} type="submit" size="m">Salvar alterações</Button>
                                    <Button style={{ color: "#FFF", backgroundColor: "#585858" }} onClick={() => setEditModalOpen(!editModalOpen)} size="m">Cancelar</Button>
                                </div>
                            </Form>
                        </>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default UserType;