import React, { useContext, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Modal, ModalBody, ModalFooter, Button, Input } from 'reactstrap';

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import AdminLayout from "./layouts/Admin/Admin";
import AuthLayout from "./layouts/Auth/Auth";
import AuthContextProvider, { AuthContext } from "./context/AuthContext";
import { ToastProvider } from "./context/ToastContext";
import CandidateContextProvider from "./context/CandidateContext";
import ExpertiseContext from "./context/ExpertiseContext";
import CompanyContextProvider from "./context/CompanyContext";
import LoadingContextProvider from "./context/LoadingContext";
import JobContextProvider from "./context/JobContext";
import EducationContext from "./context/EducationContext";
import FavoriteContextProvider from "./context/FavoriteContext";
import IndicatorContextProvider from "./context/IndicatorContext";
import AnnotationContextProvider from "./context/AnnotationContext";
import CoursesContextProvider from "./context/CoursesContext";
import CompetenceContextProvider from "./context/CompetenceContext";
import ChatGptContextProvider from "./context/ChatGptContext";
import CustomerContextProvider, { CustomerContext } from "./context/CustomerContext";
import OfficeContextProvider from "./context/OfficeContext";
import TaxTasksContextProvider, { TaxTasksContext } from "./context/TaxTasksContext";
import RoutineContextProvider from "./context/RoutineContext";
import UploadContextProvider, { UploadContext } from "./context/UploadContext";
import DownloadContextProvider, { DownloadContext } from "./context/DownloadContext";
import FilesContextProvider from "./context/FilesContext";
import RoutineTypeContextProvider from "./context/RoutineTypeContext";
import UserTypeContextProvider from "./context/UserTypeContext";
import HolidayContextProvider from "./context/HolidayContext";
import EmailContextProvider from "./context/EmailContext";
import { OfficeContext } from "../src/context/OfficeContext";
import useUser from "./context/hooks/useUser";

const rootElement =
  document.getElementById("root") ?? document.createElement("div");
const root = ReactDOM.createRoot(rootElement);

function CustomRoute() {
  const { authenticated, loadingButton, handleLogout, objUserTk } = useContext(AuthContext);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const { handleGetOfficeByOfficeId } = useContext(OfficeContext);
  const { checkFirstAccess, updatePassword, getPermissionsByUser, userPermissions } = useUser();

  useEffect(() => {
    if (objUserTk.OfficeId) {
      handleGetOfficeByOfficeId(objUserTk.OfficeId);
    }
    const checkUserAccess = async () => {
      if (objUserTk?.Type === "Customer") {
        const isFirstAccess = await checkFirstAccess(objUserTk.UserId);
        if (isFirstAccess) {
          setShowResetPasswordModal(true);
        }
      }
    };

    checkUserAccess();
  }, [objUserTk.OfficeId]);

  const handleResetPasswordClose = () => {
    setShowResetPasswordModal(false);
  };

  const handlePasswordSubmit = async () => {
    if (newPassword !== confirmPassword) {
      alert("As senhas não coincidem!");
      return;
    }

    try {
      await updatePassword(objUserTk.UserId, newPassword);
      setShowResetPasswordModal(false);
    } catch (error) {
      alert("Erro ao alterar a senha.");
    }
  };

  // setInterval(() => {
  //   handleLogout();
  // }, 60 * 60 * 1000);

  if (loadingButton) {
    return <Route>Carregando...</Route>;
  }

  // const configuration = {
  //   onUpdate: (registration: ServiceWorkerRegistration) => {
  //     if (registration && registration.waiting) {
  //       if (window.confirm('Uma nova versão do aplicativo está disponível. Deseja recarregar a página para aplicar as alterações?')) {
  //         registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  //         window.location.reload();
  //       }
  //       else {
  //         registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  //         window.location.reload();
  //       }
  //     }
  //   }
  // };

  const configuration = {
    onUpdate: (registration: ServiceWorkerRegistration) => {
      if (registration && registration.waiting) {
        setShowUpdateModal(true);
      }
    }
  };

  serviceWorkerRegistration.register(configuration);

  const handleUpdateConfirmation = () => {
    serviceWorkerRegistration.unregister();
    caches.keys().then(function (cacheNames) {
      cacheNames.forEach(function (cacheName) {
        caches.delete(cacheName);
      });
    });
    window.location.reload();
    handleLogout();
  };

  if (!authenticated || loadingButton) {
    return (
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />;
        <Route path="*" element={<Navigate to="/auth/" replace />} />
      </Routes>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="*" element={<Navigate to="/admin/" replace />} />
      </Routes>
      <Modal isOpen={showUpdateModal}>
        <ModalBody>
          Uma nova versão do aplicativo está disponível. Deseja recarregar a página para aplicar as alterações?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdateConfirmation}>OK</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={showResetPasswordModal} toggle={handleResetPasswordClose}>
        <ModalBody>
          <div className="mb-3">
            Este é o seu primeiro acesso. Por favor, altere sua senha.
          </div>
          <Input
            type="password"
            className="form-control-alternative mb-2"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="Digite sua nova senha"
          />
          <Input
            type="password"
            className="form-control-alternative"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirme sua nova senha"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handlePasswordSubmit}>Confirmar</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

root.render(
  <BrowserRouter>
    <ToastProvider>
      <LoadingContextProvider>
        <AuthContextProvider>
          <OfficeContextProvider>
            <HolidayContextProvider>
              <UserTypeContextProvider>
                <CustomerContextProvider>
                  <RoutineTypeContextProvider>
                    <RoutineContextProvider>
                      <TaxTasksContextProvider>
                        <UploadContextProvider>
                          <EmailContextProvider>
                            <DownloadContextProvider>
                              <FilesContextProvider>
                                <CustomRoute />
                              </FilesContextProvider>
                            </DownloadContextProvider>
                          </EmailContextProvider>
                        </UploadContextProvider>
                      </TaxTasksContextProvider>
                    </RoutineContextProvider>
                  </RoutineTypeContextProvider>
                </CustomerContextProvider>
              </UserTypeContextProvider>
            </HolidayContextProvider>
          </OfficeContextProvider>
        </AuthContextProvider>
      </LoadingContextProvider>
    </ToastProvider>
  </BrowserRouter >
);