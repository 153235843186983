import { useContext, useState } from "react";
import api from "../../api";
import { useToast } from "../ToastContext";
import { LoadingContext } from "../LoadingContext";
import { AuthContext } from "../AuthContext";

export interface TaxTasksType {
    id: number;
    observation: string;
    shippingDate: string;
    submissionDate: string;
    workerDownloadDate: string;
    hasDocument: boolean;
    status: string;
    routine: {
    //     id: number;
        description: string;
    //     initialDate: string;
    //     tags: string[];
        emails: string[];
        // customer: {
    //         razaoSocial: string;
    //         cnpj: string;
            // emailCobranca: string;
        // }
    };
    description: string;
    razaoSocial: string;
    tags: string[];
}

export interface UpdateTaxTasksType {
    id: number;
    observation: string;
}

export default function useTaxTasks() {
    const showToast = useToast();
    const { setLoading } = useContext(LoadingContext);
    const { handleLogout, objUserTk } = useContext(AuthContext);
    const [listTaxTasks, setListTaxTasks] = useState<TaxTasksType[]>([]);
    const [routineId, setRoutineId] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(0);
    const [taskPageCount, setTaskPageCount] = useState<number>(0);
    const [initialListTaxTasks, setInitialListTaxTasks] = useState<TaxTasksType[]>([]);
    const [TaxTasks, setTaxTasks] = useState<TaxTasksType>({
        id: 0,
        observation: "",
        shippingDate: "",
        submissionDate: "",
        workerDownloadDate: "",
        hasDocument: false,
        status: "",
        routine: {
        //     id: 0,
            description: "",
        //     initialDate: "",
        //     tags: [],
            emails: [],
            // customer: {
        //         razaoSocial: "",
        //         cnpj: "",
                // emailCobranca: "",
            // }
        },
        description: "",
        razaoSocial: "",
        tags: [],
    });

    function getAllTaxTaskPaged(
        pageNumber?: number,
        routineId?: number,
        officeId?: number,
        status?: number,
        customerName?: string,
        routineDescription?: string,
        startDate?: string,
        endDate?: string,
        tag?: string,
    ): Promise<TaxTasksType[] | null> {
        setLoading(true);
        let url = `/TaxTasks/getAllisTaxTasksPaged?pageNumber=${pageNumber}&pageSize=9`;

        if (routineId) {
            url += `&routineId=${routineId}&userOfficeId=0`;
        }

        if (officeId) {
            url += `&officeId=${officeId}&routineId=0`;
        }

        if (status) {
            url += `&status=${status}`;
        }

        if (customerName) {
            url += `&customerName=${customerName}`;
        }
        if (routineDescription) {
            url += `&routineDescription=${routineDescription}`;
        }
        if (startDate && endDate) {
            url += `&startDate=${startDate}&endDate=${endDate}`;
        }
        if (tag) {
            url += `&tag=${tag}`;
        }

        return api
            .get(url)
            .then((response) => {
                setListTaxTasks(response.data.result);
                setInitialListTaxTasks(response.data.result);
                setPageCount(response.data.pageCount);
                setTaskPageCount(response.data.pageCount);
                setLoading(false);
                return response.data.result;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao carregar tarefas. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao carregar tarefas:", error);
                return null;
            });
    }

    function handleGetRoutineIdByTaskId(taskId: number): Promise<number | null> {
        setLoading(true);
        return api
            .get(`/TaxTasks/getRoutineIdByTaskId?taskId=/${taskId}`)
            .then((response) => {
                const routineId: number = response.data;
                setLoading(false);
                return routineId;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter informações da rotina. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao obter informações da rotina:", error);
                return null;
            });
    }

    function updateTaxTasks(updateTaxTasksData: UpdateTaxTasksType): Promise<UpdateTaxTasksType | null> {
        setLoading(true);
        return api
            .put(`/TaxTasks/${updateTaxTasksData.id}`, updateTaxTasksData)
            .then((response) => {
                showToast("success", "Tarefa atualizada com sucesso.");
                setLoading(false);
                return response.data;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao atualizar tarefa. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao atualizar tarefa:", error);
                return null;
            });
    }

    function exportTaxTasks(
        pageNumber?: number,
        routineId?: number,
        officeId?: number,
        status?: number,
        customerName?: string,
        routineDescription?: string,
        startDate?: string,
        endDate?: string,
        tag?: string,
    ): Promise<void> {
        setLoading(true);
        let url = `/TaxTasks/export?pageNumber=${pageNumber}&pageSize=9`;
    
        if (routineId) {
            url += `&routineId=${routineId}&userOfficeId=0`;
        }
    
        if (officeId) {
            url += `&officeId=${officeId}&routineId=0`;
        }
    
        if (status) {
            url += `&status=${status}`;
        }
    
        if (customerName) {
            url += `&customerName=${customerName}`;
        }
    
        if (routineDescription) {
            url += `&routineDescription=${routineDescription}`;
        }
    
        if (startDate && endDate) {
            url += `&startDate=${startDate}&endDate=${endDate}`;
        }
    
        return api
            .get(url, {
                responseType: 'blob',
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `tax_tasks_${Date.now()}.csv`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
    
                showToast("success", "Exportação realizada com sucesso.");
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao exportar as tarefas. Tente novamente mais tarde.",
                        error
                    );
                }
                console.error("Erro ao exportar tarefas:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }
    
    function getTaxTaskById(taskId: number): Promise<TaxTasksType | null> {
        setLoading(true);
        return api
            .get(`/TaxTasks/getTaxTaskById/${taskId}`)
            .then((response) => {
                setLoading(false);
                return response.data;
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    handleLogout();
                    showToast(
                        "error",
                        "Sessão desconectada. Favor login novamente.",
                        error
                    );
                } else {
                    showToast(
                        "error",
                        "Erro ao obter informações da tarefa. Verifique novamente mais tarde.",
                        error
                    );
                }
                setLoading(false);
                console.error("Erro ao buscar a tarefa fiscal:", error);
                return null;
            });
    }

    return {
        listTaxTasks,
        initialListTaxTasks,
        setListTaxTasks,
        routineId,
        setRoutineId,
        pageCount,
        setPageCount,
        taskPageCount,
        setTaskPageCount,
        setTaxTasks,
        TaxTasks,
        getAllTaxTaskPaged,
        handleGetRoutineIdByTaskId,
        updateTaxTasks,
        exportTaxTasks,
        getTaxTaskById,
    };
}
