import { ReactNode, createContext } from "react";
import useEmail from "./hooks/useEmail";
import { EmailType } from "./hooks/useEmail";

interface EmailContextProviderProps {
    children: ReactNode;
}

export interface EmailContextData {
    sendNotificationEmail: (emailData: EmailType) => Promise<boolean>;
    Email: EmailType;
    setEmail: (email: EmailType) => void;
}

const initialState: EmailContextData = {
    sendNotificationEmail: async () => false,
    Email: {
        from: "",
        to: [],
        subject: "",
        text: "",
        html: "",
        templateId: "",
    },
    setEmail: () => {},
};

export const EmailContext = createContext<EmailContextData>(initialState);

export default function EmailContextProvider({
    children,
}: EmailContextProviderProps) {
    const { Email, setEmail, sendNotificationEmail } = useEmail();

    return (
        <EmailContext.Provider
            value={{
                sendNotificationEmail,
                Email,
                setEmail,
            }}
        >
            {children}
        </EmailContext.Provider>
    );
}